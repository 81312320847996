.App {
  text-align: center;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.particles-js {
  width: 100%;
  height: 100vh;
  display: flex;
  position: absolute;
  z-index: -100;
  top:0;
  left:0;
}
.rel {
  z-index: 7;
  top:100%;
  background-color: red;
  position: relative;
  display: flex;
}
.pos-rel {
  display: flex;
  width: 100%;
  height: 75px;
  text-decoration: none;
  text-align: center;
  align-items: center;
  z-index:999;
}
.generate-merged-header .site-header {
  z-index: 1;
}
nav {
  background: none;
}
* {
  font-family: 'Nunito';
}
.bg-custom {
  background-color: white;
  padding: auto;
  box-shadow: 5px 5px 0 0 #bababa;
}
.home-icon {
  color: white;
  width: 100%;
}
.content { 
  min-height: 100vh;
}
#portfolio {
  scroll-margin-top: 10vh;
}